import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex flex-column h-100"
}
const _hoisted_2 = { class: "bg-background flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile = _resolveComponent("mobile")!
  const _component_top = _resolveComponent("top")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_terms_dialog = _resolveComponent("terms-dialog")!
  const _component_footer_rsk = _resolveComponent("footer-rsk")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "h-screen" }, {
    default: _withCtx(() => [
      ($setup.isMobileDevice())
        ? (_openBlock(), _createBlock(_component_mobile, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_top),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_view, { "onUpdate:showDialog": $setup.showTermsDialog }, null, 8, ["onUpdate:showDialog"])
            ]),
            _createVNode(_component_terms_dialog, {
              showDialog: $setup.showTermsAndConditions,
              "onUpdate:showDialog": _cache[0] || (_cache[0] = ($event: any) => (($setup.showTermsAndConditions) = $event))
            }, null, 8, ["showDialog"]),
            _createVNode(_component_footer_rsk, {
              class: "flex-grow-0",
              "onUpdate:showDialog": $setup.showTermsDialog
            }, null, 8, ["onUpdate:showDialog"])
          ]))
    ]),
    _: 1
  }))
}