
import {
  ref, defineComponent, computed, watchEffect,
} from 'vue';
import { mdiCloseCircleOutline } from '@mdi/js';

export default defineComponent({
  name: 'PolicyDialog',
  props: {
    showDialogPolicy: Boolean,
  },
  setup(props, context) {
    const show = computed({
      get() {
        return props.showDialogPolicy;
      },
      set(value) {
        context.emit('update:showDialogPolicy', value);
      },
    });
    const scrolledText = ref(false);
    const scrollableArea = ref();
    const requiresScroll = ref();
    const setClosedDialog = ref(false);

    function onScroll(
      event: { target: { scrollTop: number; clientHeight: number; scrollHeight: number; }},
    ) {
      const { target: { scrollTop, clientHeight, scrollHeight } } = event;
      scrolledText.value = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
    }

    watchEffect(() => {
      if (scrollableArea.value) {
        const el = scrollableArea.value.$el;
        requiresScroll.value = el.scrollHeight > el.clientHeight;
        scrolledText.value = false;
      }
    });

    function close() {
      setClosedDialog.value = false;
      context.emit('update:showDialogPolicy', false);
    }

    return {
      show,
      scrolledText,
      onScroll,
      scrollableArea,
      requiresScroll,
      mdiCloseCircleOutline,
      close,
      setClosedDialog,
    };
  },
});
