
import {
  ref, defineComponent, computed, watchEffect,
} from 'vue';
import { useStateAttribute } from '@/common/store/helper';
import { mdiCloseCircleOutline } from '@mdi/js';
import TermsContent from '@/common/components/common/TermsContent.vue';

export default defineComponent({
  name: 'TermsDialog',
  components: { TermsContent },
  props: {
    showDialog: Boolean,
  },
  setup(props, context) {
    const areTermsAccepted = useStateAttribute<boolean>('web3Session', 'acceptedTerms');
    const show = computed({
      get() {
        return props.showDialog;
      },
      set(value) {
        context.emit('update:showDialog', value);
      },
    });
    const scrolledText = ref(false);
    const scrollableArea = ref();
    const requiresScroll = ref();

    function onScroll(
      event: { target: { scrollTop: number; clientHeight: number; scrollHeight: number; }},
    ) {
      const { target: { scrollTop, clientHeight, scrollHeight } } = event;
      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        scrolledText.value = true;
      } else {
        scrolledText.value = false;
      }
    }

    watchEffect(() => {
      if (scrollableArea.value) {
        const el = scrollableArea.value.$el;
        requiresScroll.value = el.scrollHeight > el.clientHeight;
        scrolledText.value = false;
      }
    });

    function close() {
      context.emit('update:showDialog', false);
    }

    return {
      show,
      scrolledText,
      onScroll,
      scrollableArea,
      requiresScroll,
      areTermsAccepted,
      mdiCloseCircleOutline,
      close,
    };
  },
});
