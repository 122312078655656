
export default {
  name: 'MobileView',
  setup() {
    const copy = 'Support for Mobile browsers is not supported at this time. '
      + 'Please use desktop browser';

    return {
      copy,
    };
  },
};
